/**
 * @generated SignedSource<<5561ad7cce487f12bb2dd7afc038c7c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileMainContent_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenu_viewer" | "SbMobileSearchResultContainer_viewer">;
  readonly " $fragmentType": "SbMobileMainContent_viewer";
};
export type SbMobileMainContent_viewer$key = {
  readonly " $data"?: SbMobileMainContent_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileMainContent_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileMainContent_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenu_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileSearchResultContainer_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "344517b03b19cbd4a515186b5bd77ee0";

export default node;
