/**
 * @generated SignedSource<<eeae986ae84878bff7a00b124e16c530>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenu_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileAppliedFilters_user" | "SbSharedRefineMenuDisplayPrefsSelect_user" | "SbSharedRefineMenuFilters_user">;
  readonly " $fragmentType": "SbMobileRefineMenu_user";
};
export type SbMobileRefineMenu_user$key = {
  readonly " $data"?: SbMobileRefineMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenu_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileAppliedFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4c5a7a277a68fc3f0f4b8b4a1cc84fe6";

export default node;
