/**
 * @generated SignedSource<<5fc966deb9528871a508754745430d3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileContentModuleSelector_itemSearch$data = {
  readonly browseContentModule: {
    readonly moduleType: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SbMobileContentModuleRegular_browseContentModule" | "SbSharedContentModuleBannerTiles_browseContentModule" | "SbSharedContentModuleBanner_browseContentModule">;
  } | null;
  readonly pageType: string | null;
  readonly seller: {
    readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_seller">;
  } | null;
  readonly " $fragmentType": "SbMobileContentModuleSelector_itemSearch";
};
export type SbMobileContentModuleSelector_itemSearch$key = {
  readonly " $data"?: SbMobileContentModuleSelector_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileContentModuleSelector_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "contentModuleId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileContentModuleSelector_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "sellerBrandingHelpers_seller",
          "selections": [
            {
              "alias": "isRewarded",
              "args": [
                {
                  "kind": "Literal",
                  "name": "listNames",
                  "value": [
                    "SELLER_REWARDS_1",
                    "SELLER_REWARDS_2"
                  ]
                }
              ],
              "kind": "ScalarField",
              "name": "isAnchorListsMember",
              "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "contentModuleId",
          "variableName": "contentModuleId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "browseContentModule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbMobileContentModuleRegular_browseContentModule"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedContentModuleBanner_browseContentModule"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbSharedContentModuleBannerTiles_browseContentModule"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "8f617ad86ec2be508fc4885d08eb0dae";

export default node;
