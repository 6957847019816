import { defineMessages } from 'dibs-react-intl';

export const titleMessages = defineMessages({
    furniture: {
        id: 'sb.nullSearch.furniture',
        defaultMessage: 'Furniture',
    },
    art: {
        id: 'sb.nullSearch.art',
        defaultMessage: 'Art',
    },
    jewelry: {
        id: 'sb.nullSearch.jewelry',
        defaultMessage: 'Jewelry & Watches',
    },
    fashion: {
        id: 'sb.nullSearch.fashion',
        defaultMessage: 'Fashion',
    },
    diningRoom: {
        id: 'sb.nullSearch.diningRoom',
        defaultMessage: 'Dining Room',
    },
    kitchen: {
        id: 'sb.nullSearch.kitchen',
        defaultMessage: 'Kitchen',
    },
    livingRoom: {
        id: 'sb.nullSearch.livingRoom',
        defaultMessage: 'Living Room',
    },
    bedroom: {
        id: 'sb.nullSearch.bedroom',
        defaultMessage: 'Bedroom',
    },
    furnitureCreator: {
        id: 'sb.nullSearch.furnitureCreator',
        defaultMessage: 'Eero Saarinen',
    },
    artCreator: {
        id: 'sb.nullSearch.artCreator',
        defaultMessage: 'Roy Lichtenstein',
    },
    jewelryCreator: {
        id: 'sb.nullSearch.jewelryCreator',
        defaultMessage: 'Cartier',
    },
    fashionCreator: {
        id: 'sb.nullSearch.fashionCreator',
        defaultMessage: 'Hermès',
    },
});

export const urlMessages = {
    furniture: {
        id: 'sb.nullSearch.furnitureUrl',
        defaultMessage: '/furniture/',
    },
    art: {
        id: 'sb.nullSearch.artUrl',
        defaultMessage: '/art/',
    },
    jewelry: {
        id: 'sb.nullSearch.jewelryUrl',
        defaultMessage: '/jewelry/',
    },
    fashion: {
        id: 'sb.nullSearch.fashionUrl',
        defaultMessage: '/fashion/',
    },
    furnitureCreator: {
        id: 'sb.nullSearch.furnitureCreatorUrl',
        defaultMessage: '/creators/eero-saarinen/furniture/',
    },
    artCreator: {
        id: 'sb.nullSearch.artCreatorUrl',
        defaultMessage: '/creators/roy-lichtenstein/art/',
    },
    jewelryCreator: {
        id: 'sb.nullSearch.jewelryCreatorUrl',
        defaultMessage: '/creators/cartier/',
    },
    fashionCreator: {
        id: 'sb.nullSearch.fashionCreatorUrl',
        defaultMessage: '/creators/hermes/',
    },
    diningRoom: {
        id: 'sb.nullSearch.diningRoomUrl',
        defaultMessage: '/shop/dining-room-furniture-decor/',
    },
    kitchen: {
        id: 'sb.nullSearch.kitchenUrl',
        defaultMessage: '/shop/kitchen-furniture-decor/',
    },
    livingRoom: {
        id: 'sb.nullSearch.livingRoomUrl',
        defaultMessage: '/shop/living-room-furniture-decor/',
    },
    bedroom: {
        id: 'sb.nullSearch.bedroomUrl',
        defaultMessage: '/shop/bedroom-furniture-decor/',
    },
};

export const tileMap = {
    l1: [
        {
            key: 1,
            id: 'furniture',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/furniture.jpg',
        },
        {
            key: 2,
            id: 'art',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/fine_art.jpg',
        },
        {
            key: 3,
            id: 'jewelry',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/jewelry_watches.jpg',
        },
        {
            key: 4,
            id: 'fashion',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/fashion.jpg',
        },
    ],
    creators: [
        {
            key: 1,
            id: 'furnitureCreator',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/eero_furniture.jpg',
        },
        {
            key: 2,
            id: 'artCreator',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/lichtenstein_art.jpg',
        },
        {
            key: 3,
            id: 'jewelryCreator',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/cartier_jewelry.jpg',
        },
        {
            key: 4,
            id: 'fashionCreator',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/hermes_bag.jpg',
        },
    ],
    rooms: [
        {
            key: 1,
            id: 'diningRoom',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/shop_by_room_dining_room.jpg',
        },
        {
            key: 2,
            id: 'kitchen',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/shop_by_room_kitchen.jpg',
        },
        {
            key: 3,
            id: 'livingRoom',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/shop_by_room_livingroom.jpg',
        },
        {
            key: 4,
            id: 'bedroom',
            imgSrc: 'https://a.1stdibscdn.com/assets/images/suggestions/shop_by_room_bedroom.jpg',
        },
    ],
};
