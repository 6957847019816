/**
 * Created by untitled on 09/05/2017.
 */
import PropTypes from 'prop-types';
import { FullPageWidth } from '../../components/global/FullPageWidth/FullPageWidth';
import styles from './SbMobileNullSearchResults.scss';
import { FormattedMessage } from 'dibs-react-intl';
import { titleMessages, urlMessages, tileMap } from './SbMobileNullSearchResultsConf';
import { SbMobileTileBox } from './SbMobileTileBox';

// this isn't a component linter!
// eslint-disable-next-line react/prop-types
function getTileBox({ id, ...otherProps }) {
    const message = titleMessages[id];
    const url = urlMessages[id];

    return <SbMobileTileBox titleMessage={message} urlMessage={url} {...otherProps} />;
}

const SbMobileNullSearchResults = ({
    onL1ModuleClick,
    onCreatorModuleClick,
    onRoomModuleClick,
    useLazyLoad,
}) => {
    return (
        <div>
            <div className={styles.nullSearchResultsContainer}>
                {tileMap.l1.slice(0, 2).map(tile =>
                    getTileBox({
                        className: styles.l1Box,
                        onClick: onL1ModuleClick,
                        useLazyLoad,
                        ...tile,
                    })
                )}

                <FullPageWidth hasBackground hasPadding innerClassName={styles.creatorsWrapper}>
                    {tileMap.creators.map(tile =>
                        getTileBox({
                            className: styles.creatorBox,
                            onClick: onCreatorModuleClick,
                            useLazyLoad,
                            ...tile,
                        })
                    )}
                </FullPageWidth>

                {tileMap.l1.slice(2, 4).map(tile =>
                    getTileBox({
                        className: styles.l1Box,
                        onClick: onL1ModuleClick,
                        useLazyLoad,
                        ...tile,
                    })
                )}

                <h2 className={styles.shopByRoomTitle}>
                    <FormattedMessage
                        id="sb.nullSearch.shopByRoomHeader"
                        defaultMessage="Shop by Room"
                    />
                </h2>

                {tileMap.rooms.map(tile =>
                    getTileBox({
                        className: styles.roomBox,
                        onClick: onRoomModuleClick,
                        useLazyLoad,
                        ...tile,
                    })
                )}
            </div>
        </div>
    );
};

SbMobileNullSearchResults.propTypes = {
    onL1ModuleClick: PropTypes.func,
    onCreatorModuleClick: PropTypes.func,
    onRoomModuleClick: PropTypes.func,
    useLazyLoad: PropTypes.boolean,
};

export { SbMobileNullSearchResults };
