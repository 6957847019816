/**
 * @generated SignedSource<<279d8b0247d28cd35d5a71c1d2766faf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileHeader_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileContentModuleSelector_user" | "SbSharedTitle_user">;
  readonly " $fragmentType": "SbMobileHeader_user";
};
export type SbMobileHeader_user$key = {
  readonly " $data"?: SbMobileHeader_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileHeader_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileHeader_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTitle_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileContentModuleSelector_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "acc93775d2cbfff068b1cc96aae7f1c5";

export default node;
