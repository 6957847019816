/**
 * @generated SignedSource<<57b05d9400620398abe6df8938b0b4fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileSearchResultContainer_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_viewer" | "useSbSharedItemTracking_viewer">;
  readonly " $fragmentType": "SbMobileSearchResultContainer_viewer";
};
export type SbMobileSearchResultContainer_viewer$key = {
  readonly " $data"?: SbMobileSearchResultContainer_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileSearchResultContainer_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileSearchResultContainer_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "95bd13af36e49e5b988646000bd1e58e";

export default node;
