/**
 * @generated SignedSource<<437ed25ad8dec1a05819ec7dac3249f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileSearchResultContainer_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedProductAdWrapper_itemSearch" | "SharedItemTile_itemSearch" | "useMeasurementUnit_itemSearch" | "useMergedSbAndSponsoredItems_itemSearch" | "useSbSharedItemTracking_itemSearch">;
  readonly " $fragmentType": "SbMobileSearchResultContainer_itemSearch";
};
export type SbMobileSearchResultContainer_itemSearch$key = {
  readonly " $data"?: SbMobileSearchResultContainer_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileSearchResultContainer_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileSearchResultContainer_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedProductAdWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMergedSbAndSponsoredItems_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "3cb2cb6fe834800de5bdda195f968dce";

export default node;
