import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { SbMobileRefineMenu } from '../SbMobileRefineMenu/SbMobileRefineMenu';
import { SbMobileSortRefine } from '../SbMobileSortRefine/SbMobileSortRefine';
import { SbMobileSearchResultContainer } from '../SbMobileSearchResultContainer/SbMobileSearchResultContainer';
import { SharedPagination } from '../../shared/SharedPagination/SharedPagination';
import { SbMobileAppliedFilters } from '../SbMobileAppliedFilters/SbMobileAppliedFilters';

import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';

import styles from './SbMobileMainContent.scss';
import dibsCss from 'dibs-css';

function SbMobileMainContentComponent({
    currency,
    itemSearch,
    viewer,
    user,
    isClient,
    generatedUriRef,
    showMeasurements,
    page,
}) {
    const { filters, displayUriRef, displayMaxNumberOfPages, pageType } = itemSearch;

    return (
        <>
            {/* Refine Menu */}
            <SbMobileRefineMenu
                viewer={viewer}
                currency={currency}
                filters={filters}
                itemSearch={itemSearch}
                user={user}
                uriRef={generatedUriRef}
                isClient={isClient}
            />
            {/* Sort/Refine Buttons */}
            <SbMobileSortRefine itemSearch={itemSearch} />
            {/* Applied Filters */}
            <SbMobileAppliedFilters user={user} itemSearch={itemSearch} currency={currency} />
            {/* Item Results */}
            <div
                className={classnames(
                    dibsCss.flex,
                    dibsCss.flexWrap,
                    dibsCss.mbSmall,
                    styles.searchResultContainer
                )}
                data-tn="search-results-container"
            >
                <SbMobileSearchResultContainer
                    user={user}
                    viewer={viewer || null}
                    itemSearch={itemSearch}
                    showMeasurements={showMeasurements}
                />
            </div>

            {/* Pagination */}
            <SharedPagination
                currentPage={page}
                numberOfPages={displayMaxNumberOfPages}
                displayUriRef={displayUriRef}
                hideHref={pageType === pageTypes.COLLECTION}
            />
        </>
    );
}

SbMobileMainContentComponent.propTypes = {
    currency: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    user: PropTypes.object,
    isClient: PropTypes.bool,
    generatedUriRef: PropTypes.string,
    showMeasurements: PropTypes.bool,
    page: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
    const { filters, displayPreferences } = state;

    return {
        generatedUriRef: filters.generatedUriRef,
        page: filters.page,
        showMeasurements: displayPreferences.showMeasurements,
    };
};

export const SbMobileMainContent = createFragmentContainer(
    connect(mapStateToProps)(SbMobileMainContentComponent),
    {
        viewer: graphql`
            fragment SbMobileMainContent_viewer on Viewer {
                ...SbMobileRefineMenu_viewer
                ...SbMobileSearchResultContainer_viewer
            }
        `,
        itemSearch: graphql`
            fragment SbMobileMainContent_itemSearch on ItemSearchQueryConnection {
                ...SbMobileAppliedFilters_itemSearch
                ...SbMobileRefineMenu_itemSearch
                ...SbMobileSortRefine_itemSearch
                ...SbMobileSearchResultContainer_itemSearch
                ...SbMobileAppliedFilters_itemSearch
                displayUriRef
                displayMaxNumberOfPages
                pageType
                filters {
                    ...SbMobileRefineMenu_filters
                }
            }
        `,
        user: graphql`
            fragment SbMobileMainContent_user on User {
                ...SbMobileAppliedFilters_user
                ...SbMobileRefineMenu_user
                ...SbMobileSearchResultContainer_user
                ...SbMobileAppliedFilters_user
            }
        `,
    }
);
