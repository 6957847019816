export const FACEBOOK = 'Facebook';
export const GOOGLE = 'Google';
export const APPLE = 'Apple';

export const POPUP_CLOSED_BY_USER = 'popup_closed_by_user';
export const GOOGLE_BUTTON_ACCOUNT_ID = 'googleButtonAccount';
export const GOOGLE_BUTTON_AUTH_ID = 'googleButtonAuth';
export const GOOGLE_BUTTON_EMBEDDED_AUTH_ID = 'googleButtonEmbeddedAuth';

export type SsoOptions = typeof FACEBOOK | typeof GOOGLE | typeof APPLE;
