/**
 * @generated SignedSource<<8e2ea50a8550923b169d5bd26229a685>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileContentModuleRegular_browseContentModule$data = {
  readonly description: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedStorefrontLink_browseContentModule">;
  readonly " $fragmentType": "SbMobileContentModuleRegular_browseContentModule";
};
export type SbMobileContentModuleRegular_browseContentModule$key = {
  readonly " $data"?: SbMobileContentModuleRegular_browseContentModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileContentModuleRegular_browseContentModule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileContentModuleRegular_browseContentModule",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedStorefrontLink_browseContentModule"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "ContentModuleRegular",
  "abstractKey": null
};

(node as any).hash = "8940ed035017cc7eb437fe2d119e3e32";

export default node;
