import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { ExpandableContent } from '../../components/global/ExpandableContent/ExpandableContent';
import { SbSharedStorefrontLink } from '../SbSharedStorefrontLink/SbSharedStorefrontLink';

import styles from './SbMobileContentModuleRegular.scss';

function SbMobileContentModuleRegularComponent({ browseContentModule, user }) {
    const { description } = browseContentModule;

    return (
        <ExpandableContent className={styles.description} collapseHeight={65}>
            <span
                dangerouslySetInnerHTML={{ __html: description }}
                data-tn="content-module-regular-description"
            />
            <SbSharedStorefrontLink browseContentModule={browseContentModule} user={user} />
        </ExpandableContent>
    );
}

SbMobileContentModuleRegularComponent.propTypes = {
    browseContentModule: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export const SbMobileContentModuleRegular = createFragmentContainer(
    SbMobileContentModuleRegularComponent,
    {
        user: graphql`
            fragment SbMobileContentModuleRegular_user on User {
                ...SbSharedStorefrontLink_user
            }
        `,
        browseContentModule: graphql`
            fragment SbMobileContentModuleRegular_browseContentModule on ContentModuleRegular {
                ...SbSharedStorefrontLink_browseContentModule
                description
            }
        `,
    }
);
