import PropTypes from 'prop-types';
import ArrowUp from 'dibs-icons/exports/legacy/ArrowUp';
import { FormattedMessage } from 'dibs-react-intl';
import { PageLayout } from 'dibs-elements/exports/PageLayout';
import styles from './backToTop.scss';

const scrollToTop = ({ scrollDuration, scrollInterval }) => {
    const scrollStep = -window.pageYOffset / (scrollDuration / scrollInterval);
    const scrollUp = () => {
        if (window.pageYOffset !== 0) {
            window.scrollBy(0, scrollStep);
            window.requestAnimationFrame(scrollUp);
        }
    };

    scrollUp();
};

export const BackToTop = ({ scrollDuration = 500, scrollInterval = 15 }) => {
    return (
        <PageLayout setViewportBackground={'sassyColorBuyerPrimary'} setOffset={false}>
            <div
                className={styles.wrapper}
                onClick={() => scrollToTop({ scrollDuration, scrollInterval })}
            >
                <ArrowUp className={styles.arrowUp} />
                <div className={styles.buttonText}>
                    <FormattedMessage id="BackToTop.link" defaultMessage="Top Of Page" />
                </div>
            </div>
        </PageLayout>
    );
};

BackToTop.propTypes = {
    scrollDuration: PropTypes.number,
    scrollInterval: PropTypes.number,
};
