import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import styles from './SbSharedNullSearchResultsHeader.scss';
import { type SbSharedNullSearchResultsHeader_itemSearch$key } from './__generated__/SbSharedNullSearchResultsHeader_itemSearch.graphql';

type Props = { itemSearch: SbSharedNullSearchResultsHeader_itemSearch$key };

export const SbSharedNullSearchResultsHeader: FC<Props> = function ({ itemSearch: itemSearchRef }) {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedNullSearchResultsHeader_itemSearch on ItemSearchQueryConnection {
                searchTerm
                meta {
                    header
                }
            }
        `,
        itemSearchRef
    );
    const { searchTerm } = itemSearch;
    // This component is not only used on SEARCH pages, (e.g. /designs/), so searchTerm is not always present.
    // As fallback page header is used, as in SharedSaveSearchContext.tsx
    const searchTermValue = searchTerm || itemSearch?.meta?.header;

    if (!searchTermValue) {
        return null;
    }

    return (
        <>
            <div data-tn="null-results-header" className={styles.results}>
                <FormattedMessage
                    id="sb.SbSharedNullSearchResultsHeader.zeroResults"
                    defaultMessage={`We didn't find any results for "{searchTerm}"`}
                    values={{ searchTerm: searchTermValue }}
                />
            </div>
        </>
    );
};
