import PropTypes from 'prop-types';
import { useIntl } from 'dibs-react-intl';

// Components
import LazyImage from 'dibs-react-lazy-image';
import { Link } from 'dibs-elements/exports/Link';

// Styles
import styles from './SbMobileNullSearchResults.scss';

// constants
import { VERTICAL_OFFSET_MOBILE_IMG } from '../../constants/imageConstants';

const SbMobileTileBoxComponent = ({
    titleMessage,
    imgSrc,
    className,
    urlMessage,
    useLazyLoad = true,
    onClick = () => {},
    trackingProps = {},
}) => {
    const intl = useIntl();
    const formattedTitleMessage = intl.formatMessage(titleMessage);
    const formattedUrlMessage = intl.formatMessage(urlMessage);

    const ImageElement = useLazyLoad ? (
        <LazyImage
            offsetVertical={VERTICAL_OFFSET_MOBILE_IMG}
            placeholderClass={styles.placeholder}
        >
            <img src={imgSrc} className={styles.image} alt={formattedTitleMessage} />
        </LazyImage>
    ) : (
        <img src={imgSrc} className={styles.image} alt={formattedTitleMessage} />
    );

    return (
        <Link
            href={formattedUrlMessage}
            className={`${styles.box} ${className}`}
            onClick={() => onClick(titleMessage.defaultMessage)}
        >
            <div {...trackingProps}>
                <span className={styles.title}>{formattedTitleMessage}</span>
                {ImageElement}
            </div>
        </Link>
    );
};

SbMobileTileBoxComponent.propTypes = {
    titleMessage: PropTypes.object.isRequired,
    imgSrc: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    urlMessage: PropTypes.object.isRequired,
    trackingProps: PropTypes.object,
    onClick: PropTypes.func,
    useLazyLoad: PropTypes.boolean,
};

export const SbMobileTileBox = SbMobileTileBoxComponent;
