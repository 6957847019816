import { type FC, type ReactNode, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useDispatch } from 'react-redux';
import {
    useSharedUserAndRelayVarsLoader,
    type UserRefetchFunc,
} from '../../shared/SharedUserAndRelayVarLoader';
import { showAuthModal } from '../../actions/sharedUserStorageActions';

import './mobileLayoutContainer.scss';

type Props = {
    onUserRefetch: UserRefetchFunc;
    children: ReactNode;
};

export const MobileLayoutContainer: FC<Props> = ({ onUserRefetch, children }) => {
    const dispatch = useDispatch();
    const environment = useRelayEnvironment();
    useSharedUserAndRelayVarsLoader({ onUserRefetch });

    useEffect(() => {
        /* TODO INFRA: We can get rid of showAuthModal in this file
        once mobile uses DBL message queue */
        // Argument of type '(dispatch: any) => Promise<void>' is not assignable to parameter of type 'AnyAction'.
        dispatch<$TSFixMe>(showAuthModal(environment));
        // Execute on mount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
};
