import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import {
    REGULAR_MODULE_TYPE,
    BANNER_MODULE_TYPE,
    BANNER_TILES_MODULE_TYPE,
} from '../../constants/contentModuleConstants';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { useSbSelector } from '../../reducers/useSbSelector';

import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';

import { SbMobileContentModuleRegular } from '../SbMobileContentModuleRegular/SbMobileContentModuleRegular';
import { SbSharedContentModuleBanner } from '../SbSharedContentModuleBanner/SbSharedContentModuleBanner';
import { SbSharedContentModuleBannerTiles } from '../SbSharedContentModuleBannerTiles/SbSharedContentModuleBannerTiles';
import { type SbMobileContentModuleSelector_itemSearch$key } from './__generated__/SbMobileContentModuleSelector_itemSearch.graphql';
import { type SbMobileContentModuleSelector_user$key } from './__generated__/SbMobileContentModuleSelector_user.graphql';

const itemSearchFragment = graphql`
    fragment SbMobileContentModuleSelector_itemSearch on ItemSearchQueryConnection {
        pageType
        seller {
            ...sellerBrandingHelpers_seller
        }
        browseContentModule(contentModuleId: $contentModuleId) {
            moduleType
            ...SbMobileContentModuleRegular_browseContentModule
            ...SbSharedContentModuleBanner_browseContentModule
            ...SbSharedContentModuleBannerTiles_browseContentModule
        }
    }
`;
const userFragment = graphql`
    fragment SbMobileContentModuleSelector_user on User {
        ...SbRespContentModuleRegular_user
        ...sellerBrandingHelpers_user
    }
`;

type Props = {
    itemSearch: SbMobileContentModuleSelector_itemSearch$key;
    user: SbMobileContentModuleSelector_user$key;
};

export const SbMobileContentModuleSelector: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
}) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);
    const isClient = useSbSelector(state => state.relayVariables.variables.isClient);
    const { browseContentModule, pageType, seller } = itemSearch || {};
    const isSalePage = pageType === pageTypeConstants.SALE;
    const isDealerShopPage = pageType === pageTypeConstants.DEALER;

    if (isDealerShopPage) {
        if (!isClient) {
            return null;
        }

        const shouldShowSellerDescription =
            checkRewardedSeller(seller) ||
            (!isSellerBrandingRemovalTestVariant() &&
                checkTradeUserUnderTopLevelLoyaltyTiers(user || null));
        if (!shouldShowSellerDescription) {
            return null;
        }
    }

    if (!browseContentModule) {
        return null;
    }

    switch (browseContentModule.moduleType) {
        case REGULAR_MODULE_TYPE:
            return (
                <SbMobileContentModuleRegular
                    browseContentModule={browseContentModule}
                    user={user}
                />
            );
        case BANNER_MODULE_TYPE:
            return (
                <SbSharedContentModuleBanner
                    browseContentModule={browseContentModule}
                    renderImage={({ src, imgClassName, alt, photo }) => (
                        <img
                            src={src}
                            className={imgClassName}
                            alt={alt || ''}
                            srcSet={getSrcsetString(photo || '')}
                        />
                    )}
                />
            );
        case BANNER_TILES_MODULE_TYPE:
            return (
                <SbSharedContentModuleBannerTiles
                    browseContentModule={browseContentModule}
                    isSalePage={isSalePage}
                />
            );
        default:
            return null;
    }
};
