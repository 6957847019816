/**
 * @generated SignedSource<<be56c7cd1f8de407c97a02437e11e1fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileMainContent_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileAppliedFilters_user" | "SbMobileRefineMenu_user" | "SbMobileSearchResultContainer_user">;
  readonly " $fragmentType": "SbMobileMainContent_user";
};
export type SbMobileMainContent_user$key = {
  readonly " $data"?: SbMobileMainContent_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileMainContent_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileMainContent_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileAppliedFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenu_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileSearchResultContainer_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2a59584ee508957d74644823e227cd66";

export default node;
