import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';

import { type HeadingType } from 'dibs-controlled-heading/exports/HeadingLevel';

// components
import { SbMobileContentModuleSelector } from '../SbMobileContentModuleSelector/SbMobileContentModuleSelector';
import { SbSharedTitle } from '../SbSharedTitle/SbSharedTitle';
import { SbSharedForceSearchLink } from '../SbSharedForceSearchLink/SbSharedForceSearchLink';
import { SbSharedNullSearchResultsHeader } from '../SbSharedNullSearchResultsHeader/SbSharedNullSearchResultsHeader';
import { getFilterValueByUrlLabel } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';

import { SEARCH_TYPES, FILTER_SEARCH_TYPE } from '../../constants/sbConstants';

import { type SbMobileHeader_itemSearch$key } from './__generated__/SbMobileHeader_itemSearch.graphql';
import { type SbMobileHeader_user$key } from './__generated__/SbMobileHeader_user.graphql';

const itemSearchFragment = graphql`
    fragment SbMobileHeader_itemSearch on ItemSearchQueryConnection {
        ...SbSharedTitle_itemSearch
        ...SbSharedSearchSuggestions_itemSearch
        ...SbMobileContentModuleSelector_itemSearch
        ...SbSharedForceSearchLink_itemSearch
        ...SbSharedNullSearchResultsHeader_itemSearch
        totalResults
        appliedFilters {
            name
            values {
                urlLabel
            }
        }
    }
`;
const userFragment = graphql`
    fragment SbMobileHeader_user on User {
        ...SbSharedTitle_user
        ...SbMobileContentModuleSelector_user
    }
`;

type Props = {
    itemSearch: SbMobileHeader_itemSearch$key;
    user: SbMobileHeader_user$key;
    Heading?: HeadingType;
    showContentModule?: boolean;
    showTitle?: boolean;
};

export const SbMobileHeader: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
    Heading,
    showContentModule,
    showTitle = true,
}) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);
    const { totalResults, appliedFilters } = itemSearch;
    const isNullResultPage = totalResults === 0;
    const isFuzzyResults = !!getFilterValueByUrlLabel(
        appliedFilters,
        FILTER_SEARCH_TYPE,
        SEARCH_TYPES.FUZZY
    );

    if (isNullResultPage || isFuzzyResults) {
        return showTitle ? <SbSharedNullSearchResultsHeader itemSearch={itemSearch} /> : null;
    }

    return (
        <>
            {showTitle && (
                <SbSharedTitle
                    itemSearch={itemSearch}
                    user={user}
                    Heading={Heading}
                    showContentModule={showContentModule}
                />
            )}
            <SbMobileContentModuleSelector itemSearch={itemSearch} user={user} />
            <SbSharedForceSearchLink itemSearch={itemSearch} />
        </>
    );
};
