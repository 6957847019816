/**
 * @generated SignedSource<<ec2b8b1499c4558ea4cc24bd044da254>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileSearchResultContainer_item$data = ReadonlyArray<{
  readonly isSold: boolean | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item" | "SharedItemTile_item" | "useItemShippingPrices_item" | "useSbSharedItemTracking_item" | "useSharedUrgencySignals_item">;
  readonly " $fragmentType": "SbMobileSearchResultContainer_item";
}>;
export type SbMobileSearchResultContainer_item$key = ReadonlyArray<{
  readonly " $data"?: SbMobileSearchResultContainer_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileSearchResultContainer_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbMobileSearchResultContainer_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSold",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesProvider_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSharedUrgencySignals_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useItemShippingPrices_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "showSeller",
          "variableName": "showSeller"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SharedItemTile_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "180b0ce5ed39557bc980dc7caa77527e";

export default node;
