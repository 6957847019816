import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

// constants
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';

// components
import { MobileModal } from '../../global/MobileModal/MobileModal';
import { useSbMobileRefineMenuFiltersMap } from './SbMobileRefineMenuFilters/sbMobileRefineMenuFiltersMap';
import { SbSharedRefineMenuSearchWithin } from '../SbSharedRefineMenu/SbSharedRefineMenuSearchWithin/SbSharedRefineMenuSearchWithin';
import { SbSharedRefineMenuFilters } from '../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilters';
import { SbSharedRefineMenuDisplayPrefsSelect } from '../SbSharedRefineMenu/SbSharedRefineMenuDisplayPrefsSelect/SbSharedRefineMenuDisplayPrefsSelect';
import { SbMobileRefineMenuHeader } from './SbMobileRefineMenuHeader/SbMobileRefineMenuHeader';
import { SbMobileRefineMenuSortBy } from '../SbMobileRefineMenu/SbMobileRefineMenuSortBy/SbMobileRefineMenuSortBy';
// actions
import { toggleFilterDisplay } from '../../actions/filterActions';

// helpers
import { trackSearchWithinResults } from '../../utils/tracking/searchBrowse/filterTracking';
import { trackEvent, eventNameConstants } from 'dibs-tracking';

// styles
import styles from './SbMobileRefineMenu.scss';

function fireViewResultsTracking(appliedFilters) {
    /**
     * Format: `categoryL1:furniture,color:blue,color:red,userType:consumer`
     */
    const label = appliedFilters
        .reduce((result, appliedFilter) => {
            const { name, values } = appliedFilter;

            values.forEach(({ urlLabel }) => {
                result.push(`${name}:${urlLabel}`);
            });
            return result;
        }, [])
        .join(',');

    const action = 'view results';
    trackEvent({
        category: 'results page refinement',
        eventName: eventNameConstants.EVENT_RESULTS_PAGE_REFINEMENT,
        action,
        interaction_type: action,
        label,
        step_interaction_name: label,
    });
}

function SbMobileRefineMenuComponent(props) {
    const {
        user,
        shown,
        viewer,
        filters,
        currency,
        itemSearch,
        uriRef,
        isClient,
        closeFilterDisplay,
        hasFilterUpdates,
    } = props;
    const { totalResults, appliedFilters = [], pageType } = itemSearch;

    const handleRefineMenuClose = useCallback(() => {
        fireViewResultsTracking(appliedFilters);
        closeFilterDisplay?.();
    }, [appliedFilters, closeFilterDisplay]);
    const sbMobileRefineMenuFiltersMap = useSbMobileRefineMenuFiltersMap();

    return (
        <MobileModal
            isOpen={shown}
            handleCloseClick={handleRefineMenuClose}
            usePortal={false}
            hideCloseButton
        >
            <>
                <SbMobileRefineMenuHeader
                    totalResultsCount={totalResults}
                    hideRefineMenu={handleRefineMenuClose}
                    hasFilterUpdates={hasFilterUpdates}
                />
                <div className={styles.searchBar}>
                    <SbSharedRefineMenuSearchWithin
                        onSubmit={trackSearchWithinResults}
                        itemSearch={itemSearch}
                        hideResultsCount
                    />
                </div>
            </>
            <div className={styles.separator} />
            <SbMobileRefineMenuSortBy itemSearch={itemSearch} />
            <SbSharedRefineMenuFilters
                user={user}
                viewer={viewer}
                filters={filters}
                currency={currency}
                itemSearch={itemSearch}
                filterMap={sbMobileRefineMenuFiltersMap}
                initiallyExpanded={filterName => filterName.includes('category')}
                uriRef={uriRef}
                isClient={isClient}
            />
            {pageType !== pageTypes.DESIGN && (
                <SbSharedRefineMenuDisplayPrefsSelect
                    user={user}
                    filters={filters}
                    isClient={isClient}
                    itemSearch={itemSearch}
                />
            )}
        </MobileModal>
    );
}

SbMobileRefineMenuComponent.propTypes = {
    isClient: PropTypes.bool,
    viewer: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    itemSearch: PropTypes.object.isRequired,
    user: PropTypes.object,
    uriRef: PropTypes.string,
    // redux
    closeFilterDisplay: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired,
    hasFilterUpdates: PropTypes.bool.isRequired,
};

SbMobileRefineMenuComponent = connect(
    state => ({
        shown: state.filters.filtersShown,
        hasFilterUpdates: state.filters.hasNewFilterUpdates || false,
    }),
    dispatch => ({
        closeFilterDisplay: () => dispatch(toggleFilterDisplay(false)),
    })
)(SbMobileRefineMenuComponent);

export const SbMobileRefineMenu = createFragmentContainer(SbMobileRefineMenuComponent, {
    viewer: graphql`
        fragment SbMobileRefineMenu_viewer on Viewer {
            ...SbSharedRefineMenuFilters_viewer
        }
    `,
    itemSearch: graphql`
        fragment SbMobileRefineMenu_itemSearch on ItemSearchQueryConnection {
            totalResults
            appliedFilters {
                name
                values {
                    urlLabel
                }
                canBeDismissed
            }
            pageType
            ...SbMobileAppliedFilters_itemSearch
            ...SbSharedRefineMenuFilters_itemSearch
            ...SbSharedRefineMenuSearchWithin_itemSearch
            ...SbSharedRefineMenuDisplayPrefsSelect_itemSearch
            ...SbMobileRefineMenuSortBy_itemSearch
        }
    `,
    filters: graphql`
        fragment SbMobileRefineMenu_filters on SearchBrowseFilter @relay(plural: true) {
            ...SbSharedRefineMenuFilters_filters
            ...SbSharedRefineMenuDisplayPrefsSelect_filters
        }
    `,
    user: graphql`
        fragment SbMobileRefineMenu_user on User {
            ...SbMobileAppliedFilters_user
            ...SbSharedRefineMenuFilters_user
            ...SbSharedRefineMenuDisplayPrefsSelect_user
        }
    `,
});
